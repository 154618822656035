import React, { useEffect, useState } from "react";
import { CallAxios } from "../../components/axios/axiosFunctions";
import { Link } from "gatsby";
import { Container, Table, Button } from "react-bootstrap";

// markup
const Dashboard = () => {
  const [myTable, setMyTable] = useState([]);
  async function getData() {
    let x = await CallAxios(
      "https://dakaramerica.com/scripts/getCars.php",
      "get"
    );
    setMyTable(x);
    console.log(x);
    console.log(x[1]);
  }

  function setCarInStorage(CarId) {
    localStorage.setItem("selectedCar", CarId);
  }

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <h1>Dashboard</h1>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <Link to="./addcar">
          <Button>Add Car +</Button>
        </Link>
      </div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Image</th>
            <th>Make</th>
            <th>Year</th>
            <th>Model</th>
            <th>KM</th>
            <th>Price</th>
            <th>Change</th>
          </tr>
        </thead>
        <tbody>
          {myTable.map((item) => (
            <tr>
              <td>
                <img
                  src={
                    "https://dakaramerica.com/images/" +
                    item.carphotos +
                    "0.jpg"
                  }
                  alt=""
                  width="80"
                />
              </td>
              <td>{item.carmake}</td>
              <td>{item.caryear}</td>
              <td>{item.carmodel}</td>
              <td>{item.carkm}</td>
              <td>{item.carprice}</td>
              <td>{item.id}</td>
              <Link to="./carfile">
                <Button onClick={() => setCarInStorage(item.id)}>Change</Button>
              </Link>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default Dashboard;
